<script>
import {getRoleString} from "@/helpers/functions";

export default {
  name: "profile-hero",
  methods: {getRoleString},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      city: null,
      role: null,
      fullName: null,
    }
  },
  mounted() {
    if (this.user){
      this.city = this.user.city.name
      // this.role = getRoleString(this.user.role)
      this.fullName = this.user.firstname + ' ' + this.user.lastname
    }
  }
}
</script>

<template>
  <div class="row g-4">
    <div class="col-auto">
      <div class="avatar-lg">
        <img
            src="@/assets/images/users/user-dummy-img.jpg"
            alt="user-img"
            class="img-thumbnail rounded-circle"
        />
      </div>
    </div>
    <!--end col-->
    <div class="col">
      <div class="p-2">
        <h3 class="text-white mb-1">{{fullName}}</h3>
        <p class="text-white-75">{{role}}</p>
        <div class="hstack text-white-50 gap-1">
          <div class="">
            <i
                class="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"
            ></i
            ><span class="text-capitalize">{{ city }}</span>, Cameroun
          </div>
          <!--              <div>-->
          <!--                <i-->
          <!--                    class="ri-building-line me-1 text-white-75 fs-16 align-middle"-->
          <!--                ></i-->
          <!--                >Themesbrand-->
          <!--              </div>-->
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
</template>

<style scoped>

</style>