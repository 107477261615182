<script>
export default {
  name: "profile-navigation"
}
</script>

<template>
  <div class="d-flex">
    <!-- Nav tabs -->
    <ul
        class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
    >
      <li class="nav-item">
        <a
            class="nav-link fs-14 active"
            data-bs-toggle="tab"
            href="#overview-tab"
            role="tab"
        >
          <i class="ri-user-2-fill d-inline-block d-md-none"></i>
          <span class="d-none d-md-inline-block">Vue d'ensemble</span>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link fs-14"
            data-bs-toggle="tab"
            href="#activities"
            role="tab"
        >
          <i class="ri-phone-fill d-inline-block d-md-none"></i>
          <span class="d-none d-md-inline-block">Numéros</span>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link fs-14"
            data-bs-toggle="tab"
            href="#projects"
            role="tab"
        >
          <i class="ri-lock-fill d-inline-block d-md-none"></i>
          <span class="d-none d-md-inline-block">Mise à jour profile</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>