<script>
import SwiperCore, { Thumbs, Navigation, Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Navigation, Pagination]);

import Layout from "@/layouts/main.vue";
import appConfig from "../../../app.config";
import store from "@/state/store";
import UpdatePasswordForm from "@/components/dashboard/profile/update-password-form.vue";
import UpdateUserInfo from "@/components/dashboard/profile/update-user-info.vue";
import UserInfo from "@/components/dashboard/profile/user-info.vue";
import ProfileHero from "@/components/dashboard/profile/profile-hero.vue";
import ProfileNavigation from "@/components/dashboard/profile/profile-navigation.vue";

export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    ProfileNavigation,
    ProfileHero,
    UserInfo,
    UpdateUserInfo,
    UpdatePasswordForm,
    Layout,
  },
  data() {
    return {
      user: store.getters["auth/userInfo"],
      allPhoneNumbers: [],
      userRole: null,
    }
  },

  mounted() {
    this.getCurrentUserInfo()
  },
  methods: {
    getCurrentUserInfo(){
      const user = store.getters["auth/userInfo"]
      user.authorisedPhoneNumbers.map(apn => {
        this.allPhoneNumbers.push(apn)
      })
    },
  }
};
</script>

<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img
            src="@/assets/images/profile-bg.jpg"
            alt=""
            class="profile-wid-img"
        />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <profile-hero :user="user"/>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <profile-navigation/>
          <div class="tab-content pt-4 text-muted">
            <div class="tab-pane active" id="overview-tab" role="tabpanel">
              <div class="row">
                <div class="col-xxl-3">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-5">Completez Votre Profil</h5>
                      <div
                          class="progress animated-progess custom-progress progress-label"
                      >
                        <div
                            class="progress-bar bg-danger"
                            role="progressbar"
                            style="width: 70%"
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                          <div class="label">70%</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <user-info :user="user" />
                  <!--end card-->
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="activities" role="tabpanel">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title mb-3">Numéros de Téléphone Validés</h5>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="projects" role="tabpanel">
              <div class="card">
                <div class="card-header">
                  <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                        <i class="fas fa-home"></i>
                        Informations Personnelles
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                        <i class="far fa-user"></i>
                        Modifiez le mot de passe
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body p-4">
                  <div class="tab-content">
                    <div class="tab-pane active" id="personalDetails" role="tabpanel">
                      <update-user-info :user="user"/>
                    </div>
                    <div class="tab-pane" id="changePassword" role="tabpanel">
                      <update-password-form :user="user" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
