<script>
import API from "@/plugins/axios-method";

export default {
  name: "update-password-form",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isSubmitting: false,
      id: null,
    };
  },
  mounted() {
    this.id = this.user?.uniqId;
  },
  methods: {
    updatePassword(){
      this.isSubmitting = true
      API.patch('/users/' + this.id + '/password', {
        oldPassword: this.oldPassword,
        rawPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      })
          .then(() => {
            this.$router.push('/logout')
          })
          .catch(() => {
            this.isSubmitting = false
          })
    },
  }
}
</script>

<template>
  <form @submit.prevent="updatePassword">
    <div class="row g-2">
      <div class="col-lg-4">
        <div>
          <label for="oldpasswordInput" class="form-label">Ancien mot de passe*</label>
          <input v-model="oldPassword" required type="password" class="form-control" id="oldpasswordInput" placeholder="Ancien mot de passe" />
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-4">
        <div>
          <label for="newpasswordInput" class="form-label">Nouveau mot de passe*</label>
          <input v-model="newPassword" required type="password" class="form-control" id="newpasswordInput" placeholder="Nouveau mot de passe" />
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-4">
        <div>
          <label for="confirmpasswordInput" class="form-label">Confirmez le nouveau mot de passe*</label>
          <input v-model="confirmPassword" required type="password" class="form-control" id="confirmpasswordInput" placeholder="Confirmez le nouveau mot de passe" />
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div class="text-end">
          <button :disabled="isSubmitting" type="submit" class="btn btn-primary">
            Changez mot de passe
          </button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>

<style scoped>

</style>