<script>
import API from "@/plugins/axios-method";

export default {
  name: "update-user-info",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lastName: null,
      firstName: null,
      birthDate: null,
      email: null,
      nicNumber: null,
      uin: null,
      sex: null,
      sexArr: [
        {value: 'male', label: 'Homme'},
        {value: 'female', label: 'Femme'},
      ],
      isSubmitted: false,
      isSubmitting: false,
      id: null,
    }
  },
  mounted() {
    if (this.user) {
      this.lastName = this.user.lastname
      this.firstName = this.user.firstname
      this.birthDate = this.user.birthDate.split('T')[0]
      this.email = this.user.email
      this.nicNumber = this.user.nicNumber
      this.uin = this.user.uin
      this.sex = this.user.sex
      this.id = this.user.uniqId
    }
  },
  methods: {
    updateUser(){
      this.isSubmitting = true
      API.patch('/users/' + this.id, {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        birthDate: this.birthDate,
        uin: this.uin,
        nicNumber: this.nicNumber,
        sex: this.sex,
      })
          .then(response => {
            this.isSubmitting = false
            if (response.status === 200){
              this.$store.commit('auth/SET_CURRENT_USER', response.data)
            }
          })
          .catch(error => {
            this.isSubmitting = false
            console.log(error)
          })
    },
  }
}
</script>

<template>
  <form @submit.prevent="updateUser">
    <div class="row g-3">
      <div class="col-md-6">
        <div>
          <label for="firstName" class="form-label">Nom</label>
          <input type="text" class="form-control" id="firstName" v-model="lastName" placeholder="Nom">
        </div>
      </div><!--end col-->
      <div class="col-md-6">
        <div>
          <label for="lastName" class="form-label">Prenom</label>
          <input type="text" class="form-control" id="lastName" v-model="firstName" placeholder="Prenom">
        </div>
      </div><!--end col-->
      <div class="col-md-6">
        <label for="birtdate" class="form-label">Date naissance</label>
        <input type="date" class="form-control" id="birthdate" v-model="birthDate">
      </div>
      <div class="col-md-6">
        <label for="sex" class="form-label">Sexe</label>
        <select v-model="sex" class="form-select mb-3">
          <option disabled selected>Choisir le sex</option>
          <option v-for="(sex, index) in sexArr" :key="index" :value="sex.value">{{ sex.label }}</option>
        </select>
      </div>
      <div class="col-12">
        <div>
          <label for="emailInput" class="form-label">Email</label>
          <input type="email" class="form-control" id="emailInput" placeholder="Email" v-model="email">
        </div>
      </div><!--end col-->

      <div class="col-md-6">
        <div>
          <label for="nicNumber" class="form-label">Numéro CNI</label>
          <input type="text" class="form-control" id="nicNumber" v-model="nicNumber" placeholder="Numéro CNI">
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <label for="uin" class="form-label">Numéro d'indentification unique</label>
          <input type="text" class="form-control" id="uin" v-model="uin" placeholder="Numéro d'indentification unique">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button type="submit" :disabled="isSubmitting" class="btn btn-primary">Enregistrer</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>

<style scoped>

</style>